import React from "react";
import {StaticQuery, graphql} from "gatsby";
import * as mapStyle from "./map.module.css"
import LeafletMap from "./leafletMap"



const Mapa = () => (
    
    <StaticQuery
    query={graphql`
        query{
          strapiHomepage {
                Mapa{
                    Title
                }
              }
        }
    `}
    render={(data) => (
        <div className={mapStyle.main}>
                <span>{data.strapiHomepage.Mapa.Title}</span>
                <div className={mapStyle.mapContainer} id="map">
                {typeof window !== 'undefined' &&
                    <LeafletMap
                    position={[54.2557914,16.0516159]}
                    zoom={15}
                    markerText={"Hello, this is a marker"}
                    />
                }
                </div>
           
            </div>
        
    )}
    
    />
    
);

export default Mapa;




