import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import * as footerStyle from "./footer.module.css"

const Footer = () => (
    <StaticQuery
    query={graphql`
        query{
            strapiGlobal{
                Email
                Tel
                Tel2
                opening_hours
            }
            allStrapiBlock{
                edges{
                    node{
                       title
                       slug
                    }
                }
            }
    }
    `}
    render={(data) => (
        <footer id="kontakt" className={`${footerStyle.main} container`}>
            <div>
               <p className={`${footerStyle.yellow__background}`}>{data.strapiGlobal.Tel}</p>
               <p className={`${footerStyle.yellow__background}`}>{data.strapiGlobal.Tel2}</p>
               <p className={`${footerStyle.yellow__background}`}>{data.strapiGlobal.Email}</p>
               <p>{data.strapiGlobal.opening_hours}</p>
            </div>
            <div>
                <ul>
                    {data.allStrapiBlock.edges.map((name,i) => (
                        <li className={footerStyle.list__element} key={`category__${name.node.title}`}>
                            <Link to={`#${name.node.slug}`}>
                                {name.node.title}
                             </Link>
                             </li>
                    ))}
                   
                </ul>
            </div>
        </footer>   
    )}
    />
);

export default Footer;
