import React from "react";
import { StaticQuery, graphql,Link } from "gatsby";
import Img from 'gatsby-image'
import * as heroStyle from "./hero.module.css"
const Hero = () => (
    <StaticQuery
    query={graphql`
        query{
            strapiHomepage {
                hero {
                  title
                  title2
                  Description
                  Button
                  Subtitle
                  Image{
                      publicURL
                      childImageSharp{
                        fluid(maxWidth: 1500, quality: 100){
                            ...GatsbyImageSharpFluid
                        }
                      }
                  }
                }
              }
    }
    `}
    render={(data) => (
        <div className={heroStyle.slider} >
            <div className={heroStyle.text__wrapper}>
                <h1 className="">{data.strapiHomepage.hero.title}<p className="yellow__text">{data.strapiHomepage.hero.title2}</p></h1>
                <h2>{data.strapiHomepage.hero.Subtitle}</h2>
                <span>{data.strapiHomepage.hero.Description}</span>
                <Link to ="/#kontakt">{data.strapiHomepage.hero.Button}</Link>
              
            </div>
            <Img
                fluid={data.strapiHomepage.hero.Image.childImageSharp.fluid}
          />
        </div>
        
    )}
    />
);
export default Hero;
